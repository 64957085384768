
import React, { useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import MicIcon from "@mui/icons-material/Mic";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";

export const FactsSpeechRecognition = (props) => {
  const { listening, resetTranscript, transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [recording, setRecording] = useState(false);

  let transcriptMessage = "";

  // Check if the browser supports speech recognition
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const handleStartRecording = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: props.language || "en-US",
    });
    setRecording(true);
    transcriptMessage = transcript;
    resetTranscript();
    ExecuteLayoutEventMethods(props.whenrecording, transcriptMessage);
  };

  const handleStopRecording = () => {
    SpeechRecognition.stopListening();
    setRecording(false);
    transcriptMessage = transcript;
    resetTranscript();
    if(transcript){
      ExecuteLayoutEventMethods(props.whenrecordingstop, transcriptMessage);
    }
   
  };


  const micContainerStyle = {
    position: "relative",
    width: "34px",
    height: "34px", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "visible", 
  };

  const micIconStyle = {
    fontSize: "34px",
    cursor: "pointer",
    zIndex: 2,
    color: recording ? "#007bff" : "#000000", 
    transition: "color 0.3s ease-in-out",
  };

  const pulseWaveStyle = {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background:"rgba(0, 123, 255, 0.3)",
    transform: "translate(-50%, -50%) scale(1)",
    zIndex: 1,
    transition:  "transform 0.3s ease-in-out, opacity 0.3s",
    animation: "pulse 1.5s infinite" ,
  };

  const keyframes = `
    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.7;
      }
      50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0.3;
      }
      100% {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      }
    }
  `;

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <style>{recording && keyframes}</style>

      <div style={micContainerStyle}>
   {recording && <div style={{ ...pulseWaveStyle, width: "100%", height: "100%" }}></div>}

        {/* {recordingStopped &&    */}
           <MicIcon
          onClick={recording ? handleStopRecording : handleStartRecording}
          style={{
            ...micIconStyle,
            color: recording ? "#007bff" : "#000000", 
            cursor: browserSupportsSpeechRecognition ? "pointer" : "not-allowed",
          }}
          aria-label={recording ? "Stop recording" : "Start recording"}
        />
        {/* } */}
   
      </div>
    </div>
  );
};


