import React, { Component } from "react";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { getProcessedDynamic } from "../../../General/commonFunctions";

// Helper function to convert Base64 to a Blob
const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64); // Decode base64 string
  const byteNumbers = new Array(byteCharacters.length);

  // Create byte array
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array
  return new Blob([byteArray], { type: contentType });
};

class FactsShareButton extends Component {
  constructor(props) {
    super(props);
    let _propsRedux = null;

    if (props.appendprops)
      _propsRedux = getProcessedDynamic(props.appendprops, props.refData);
    this.state = {
      propsRedux: _propsRedux,
    };
    this.controlRef = React.createRef();

    // Bind the method to ensure 'this' context is correct
    this.handleShare = this.handleShare.bind(this);
  }

  // Function to create a PDF Blob from Base64
  getPDFFile(base64image, pdfName) {
    const base64String = base64image; // Correct base64 string
    const base64ContentArray = base64String.split(",");
    const base64WithoutPrefix =
      base64ContentArray.length > 1
        ? base64ContentArray[1]
        : base64ContentArray[0];

    // Convert Base64 string to a Blob
    const pdfBlob = base64ToBlob(base64WithoutPrefix, "application/pdf");
    // Create a File object from the Blob
    const pdfFile = new File([pdfBlob], `${pdfName}.pdf`, {
      type: "application/pdf",
    });
    return pdfFile;
  }

  async handleShare() {
    const { bas64data, pdfName } = this.props; // Extract props
    const pdfFile = this.getPDFFile(bas64data, pdfName); // Retrieve the PDF file

    try {
      // Check if the browser supports the Web Share API and can share files
      if (
        navigator.share &&
        navigator.canShare &&
        navigator.canShare({ files: [pdfFile] })
      ) {
        // Use the Web Share API to share the PDF file
        await navigator.share({
          title: "Share PDF",
          text: "Check out this PDF file!",
          files: [pdfFile], // Share the PDF file
        });
        console.log("PDF shared successfully");
      } else {
        console.log(
          "Sharing not supported or file type not supported on this device"
        );
        alert("Sharing is not supported on your device/browser");
      }
    } catch (error) {
      console.error("Error sharing PDF:", error);
    }
  }

  downloadPDF(base64image, pdfName) {
    const pdfFile = this.getPDFFile(base64image, pdfName); // Retrieve the PDF file

    // Create a URL for the Blob and create a download link
    const url = URL.createObjectURL(pdfFile);
    const a = document.createElement("a");
    a.href = url;
    a.download = pdfFile.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url); // Free up memory
  }

  ripOffControlSpecificAttributes() {
    const excluded = ["whenclick", "appendprops", "refData"];
    return Object.keys(this.props)
      .filter((t) => !excluded.includes(t))
      .reduce((obj, key) => {
        obj[key] = this.props[key];
        return obj;
      }, {});
  }

  render() {
    let newProps = this.ripOffControlSpecificAttributes();
    return (
      <div>
        <IconButton
          {...newProps}
          className={`${newProps.className} factsIconButton`}
          {...this.state.propsRedux}
          onClick={this.handleShare} // Bind the method properly
        >
          <Icon
            style={{
              fontSize:
                this.state.propsRedux?.style?.fontSize ||
                newProps?.style?.fontSize,
            }}
          >
            {this.props.children}
          </Icon>
        </IconButton>
      </div>
    );
  }
}

export default FactsShareButton;
