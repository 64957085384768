import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import Tesseract from "tesseract.js";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions"; // Import added

export const FactsOCRTextReading = (props) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [extractedValue, setExtractedValue] = useState(null);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const videoConstraints = {
    facingMode: isMobileDevice() ? "environment" : "user",
  };

  const detectText = async (imgSrc) => {
    try {
      console.log("Starting text detection...");
      const {
        data: { text: extractedText, words },
      } = await Tesseract.recognize(imgSrc, "eng", {
        rectangles: true, // Enable bounding boxes
      });

      setExtractedValue(extractedText); // Set the raw extracted text
      drawBoundingBoxes(words); // Draw bounding boxes around words

      // Call ExecuteLayoutEventMethods after successful scan
      ExecuteLayoutEventMethods(props.whenscan, extractedText);
    } catch (error) {
      console.error("Error recognizing text:", error);
    }
  };

  const drawBoundingBoxes = (words) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const webcam = webcamRef.current;

    if (!canvas || !context || !webcam || !webcam.video) {
      console.error("Canvas, context, or webcam not available");
      return;
    }

    const videoWidth = webcam.video.videoWidth;
    const videoHeight = webcam.video.videoHeight;
    canvas.width = videoWidth;
    canvas.height = videoHeight;

    context.clearRect(0, 0, canvas.width, canvas.height);

    words.forEach(({ bbox }) => {
      const { x0, y0, x1, y1 } = bbox;
      context.strokeStyle = "red";
      context.lineWidth = 2;
      context.strokeRect(x0, y0, x1 - x0, y1 - y0); // Draw rectangle
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (webcamRef.current) {
        const imgSrc = webcamRef.current.getScreenshot();
        if (imgSrc) {
          detectText(imgSrc);
        }
      }
    }, 3000); // Capture a frame every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        ...props.styles?.container, // Use styles from props
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          ...props.styles?.videoContainer, // Use styles from props
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "400px",
          height: "400px",
          position: "relative",
        }}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{ width: "100%", height: "100%", objectFit: "cover" }} // Make webcam fill the container
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            width: "400px",
            height: "400px",
            zIndex: 1,
          }}
        />
      </div>
    </div>
  );
};
