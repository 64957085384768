export const STORE_DATASET = 'STORE_DATASET';
export const STORE_DATASET_FIELD = 'STORE_DATASET_FIELD';
export const MERGE_OBJECT_DATASET = 'MERGE_OBJECT_DATASET';
export const MERGE_ARRAY_DATASET = 'MERGE_ARRAY_DATASET';
export const SUBMIT_BUTTON_TRIGGERED = "SUBMIT_BUTTON_TRIGGERED";

//LOGIN && USER

export const STORE_USER = 'STORE_USER';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SHOW_APP_BUSY = 'SHOW_APP_BUSY';
export const HIDE_APP_BUSY = 'HIDE_APP_BUSY'; 
