import React, { Component } from "react";
import html2canvas from "html2canvas";

class FactsShareScreen extends Component {
  async captureAndShare(id) {
    console.log(id);
    // Capture the content of the div with id 'shareable-div'
    const div = document.getElementById(id);

    try {
      const canvas = await html2canvas(div);

      // Convert canvas to Blob
      canvas.toBlob(async (blob) => {
        if (!blob) return;

        // Create a file from the Blob with an image name and type
        const imgFile = new File([blob], "screenshot.png", {
          type: "image/png",
        });

        // Check if sharing is supported
        if (navigator.canShare && navigator.canShare({ files: [imgFile] })) {
          try {
            // Use the Web Share API to share the image file
            await navigator.share({
              title: "Screenshot",
              text: "Sharing a screenshot of selected div.",
              files: [imgFile],
            });
            console.log("Screen shared successfully");
          } catch (error) {
            console.error("Error sharing screen:", error);
          }
        } else {
          console.log(
            "Sharing not supported or file type not supported on this device"
          );
          alert("Sharing is not supported on your device/browser.");
        }
      }, "image/png");
    } catch (error) {
      console.error("Error capturing the screen:", error);
    }
  }

  ripOffControlSpecificAttributes() {
    const excluded = ["whenclick", "appendprops", "refData"];
    return Object.keys(this.props)
      .filter((t) => !excluded.includes(t))
      .reduce((obj, key) => {
        obj[key] = this.props[key];
        return obj;
      }, {});
  }
  render() {
    let newProps = this.ripOffControlSpecificAttributes();
    return (
      <div>
        <button onClick={() => this.captureAndShare(newProps.targetId)}>
          {" "}
          {this.props.children}
        </button>
      </div>
    );
  }
}
export default FactsShareScreen;
