import React, { Component } from "react";
import "devextreme/dist/css/dx.light.css";
import { ContextMenu } from "devextreme-react/context-menu";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
class FactsContextMenu extends Component {
  async handleButtonClick() {
    await ExecuteLayoutEventMethods(this.props.whenclick, {
      ...this.props.refData,
      controlid: this.btnID,
    });
  }
  ripOffControlSpecificAttributes() {
    const excluded = ["whenclick", "appendprops", "refData", "items"];
    return Object.keys(this.props)
      .filter((t) => !excluded.includes(t))
      .reduce((obj, key) => {
        obj[key] = this.props[key];
        return obj;
      }, {});
  }
  async handleOnClick(e) {
    await ExecuteLayoutEventMethods(e.itemData.whenclick, {
      ...this.props.refData,
      controlid: this.btnID,
    });
  }
  async onContentReady(e) {
    await ExecuteLayoutEventMethods(this.props.onContentReady, {
      ...this.props.refData,
      controlid: this.btnID,
    });
  }

  render() {
    // Convert the object to an array of values
    const itemsArray = Object.values(this.props.items);
    // Filter the array to include only items where `hide` is false
    const visibleItems = itemsArray.filter((item) => !item.hide);
    let newProps = this.ripOffControlSpecificAttributes();

    return (
      <div>
        <ContextMenu
          {...newProps}
          items={visibleItems}
          target={`#${newProps.target}`}
          onItemClick={(e) => this.handleOnClick(e)}
        />
      </div>
    );
  }
}
export default FactsContextMenu;
