import React, { Component } from "react";
import "devextreme/dist/css/dx.light.css";
import { Button } from "devextreme-react/button";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";
class FactsDevButton extends Component {
  async handleButtonClick() {
    console.log(this.props);
    await ExecuteLayoutEventMethods(this.props.whenclick, {
      ...this.props.refData,
      controlid: this.btnID,
    });
  }
  ripOffControlSpecificAttributes() {
    const excluded = ["whenclick", "appendprops", "refData"];
    return Object.keys(this.props)
      .filter((t) => !excluded.includes(t))
      .reduce((obj, key) => {
        obj[key] = this.props[key];
        return obj;
      }, {});
  }
  render() {
    let newProps = this.ripOffControlSpecificAttributes();

    return (
      <div className="App">
        <Button
          id={this.btnID}
          {...newProps}
          onClick={this.handleButtonClick.bind(this)}
        />
      </div>
    );
  }
}
export default FactsDevButton;
