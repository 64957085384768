import React, { useState, useRef, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ownStore } from "../../../AppOwnState/ownState";

const CustomDropdown = ({
  enteredCode,
  getCountryCode,
  getCountryRegEx,
  listdset,
  placeholder = "Country",
  onChange,
  setCountryCode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [listData, setListData] = useState(ownStore.getState(listdset) ?? []);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (ownStore.getState(listdset)) {
      setListData(ownStore.getState(listdset));
    }
  }, [ownStore.getState(listdset)]);

  useEffect(() => {
    listData.forEach((option) => {
      if (option?.CODE?.includes(enteredCode)) {
        setSelectedOption(option);
        getCountryRegEx(option?.REGEX);
      }
    });
  }, [enteredCode]);

  useEffect(() => {
    if (setCountryCode) {
      listData.forEach((option) => {
        if (setCountryCode?.startsWith(option?.CODE)) {
          setSelectedOption(option);
          getCountryRegEx(option?.REGEX);
        }
      });
    }
  }, [setCountryCode]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    getCountryCode(option?.CODE);
    getCountryRegEx(option?.REGEX);
    setIsOpen(false);
    if (onChange) onChange(option);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      style={{ background: "#fff" }}
      className="dropdown-container small-ctr"
      ref={dropdownRef}
    >
      <div
        style={{ background: "#fff" }}
        className="dropdown-header"
        onClick={handleToggleDropdown}
      >
        {selectedOption ? (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img
              style={{
                height: "15px",
                width: "20px",
                border: "1px solid lightgrey",
              }}
              src={
                "/res/country/" +
                selectedOption?.COUNTRY?.toUpperCase() +
                ".png"
              }
            />
            <span>{selectedOption?.COUNTRY}</span>
          </div>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
        <ArrowDropDownIcon
          className={`dropdown-icon ${isOpen ? "open" : ""}`}
        />
      </div>
      {isOpen && (
        <div className="dropdown-list-container">
          <ul className="dropdown-list">
            {listData?.map((option, i) => (
              <li
                key={i}
                onClick={() => handleOptionClick(option)}
                className={`list-item ${
                  selectedOption?.value === option.value ? "selected" : ""
                }`}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                >
                  <img
                    style={{
                      height: "15px",
                      width: "20px",
                      border: "1px solid lightgrey",
                    }}
                    src={
                      "/res/country/" + option?.COUNTRY?.toUpperCase() + ".png"
                    }
                  />
                  <span>
                    {option?.CN_NAME}({option?.CODE})
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
