import { useState, useEffect } from "react";
import { useZxing } from "react-zxing";
import { ExecuteLayoutEventMethods } from "../../../General/commonFunctions";

const allFormats = [
  "QR_CODE",
  "EAN_13",
  "CODE_128",
  "EAN_8",
  "UPC_A",
  "UPC_E",
  "ITF",
  "CODE_39",
  "CODABAR",
  "DATA_MATRIX",
  "AZTEC",
  "PDF_417",
  "MAXICODE",
  "RSS_14",
  "MSI",
  "PLESSEY",
];

export const FactsXzingBarcode = (props) => {
  const [result, setResult] = useState("");
  const [isScanning, setIsScanning] = useState(true);
  const [scanTimeout, setScanTimeout] = useState(null);

  const handleDecodeResult = (result) => {
    if (!scanTimeout) {
      if (result) {
        const decodedText = result.getText();
        setResult(decodedText);
        ExecuteLayoutEventMethods(props.whenscan, result);
      }
      setScanTimeout(setTimeout(() => setScanTimeout(null), 500));
    }
  };

  const { ref } = useZxing({
    timeBetweenDecodingAttempts: 150,
    onDecodeResult: handleDecodeResult,
    video: {
      facingMode: "environment",
      width: { ideal: 640 },
      height: { ideal: 480 },
      frameRate: { ideal: 15 },
    },
    constraints: {
      formats: props.formats || allFormats,
    },
  });

  useEffect(() => {
    if (props.shouldScan !== undefined) {
      setIsScanning(props.shouldScan);
    }
  }, [props.shouldScan]);

  return (
    <>
      {isScanning && <video ref={ref} autoFocus={true} />}
    </>
  );
};
