import {
    AUTH_LOGIN, AUTH_LOGOUT, HIDE_APP_BUSY, MERGE_ARRAY_DATASET, MERGE_OBJECT_DATASET,
    SHOW_APP_BUSY,
    STORE_DATASET, STORE_DATASET_FIELD, STORE_USER, SUBMIT_BUTTON_TRIGGERED
} from "../AppOwnState/ownStateActionTypes";
import { getUniqueID } from "../General/globals";
import { ownObject } from "./ownObjectStore";

class OwnState {
    constructor() {
        this.callBackFunctions = [];
        this.submitTriggered = false;
        // this.formReadyForSubmission = false;
        this.componentsValidationStateObj = {}
    }

    subscribe(callBackFn) {
        let fnKey = getUniqueID();
        let callBakObj = { key: fnKey, fn: callBackFn }
        this.callBackFunctions.push(callBakObj);
        return () => { this.callBackFunctions = this.callBackFunctions.filter((obj) => obj.key !== fnKey) };
    }

     // Method for updating validation status for each component
     updateValidationStatus(componentKey, isValid) {
        this.componentsValidationStateObj[componentKey] = isValid; // Update component's validation status
        // Check if all validation statuses are true
        // this.formReadyForSubmission = Object.values(this.componentsValidationStateObj).every(status => status === true);
        // Notify all subscribers (e.g., components) of state change
        // console.log(this.formReadyForSubmission,this.componentsValidationStateObj,'formReady23')

    }
    getState(dsetName) {
        return ownObject.getDataSet(dsetName);
    }

    getFullState() {
        return ownObject.getFullDataSet();
    }

    getFormReadyStatus() {
        return this.componentsValidationStateObj
        // return this.formReadyForSubmission;
    }
    dispatch(action) {
        if (action && action.type) {
            let actionDSetName = action?.payload?.dataSetName;
            switch (action.type) {
                case STORE_DATASET:
                    if (action.payload.dataSetName) ownObject.storeDataSet(action.payload);
                    break;
                case MERGE_OBJECT_DATASET:
                    if (action.payload.dataSetName) ownObject.mergeDataSet(action.payload);
                    break;
                case MERGE_ARRAY_DATASET:
                    if (action.payload.dataSetName) ownObject.mergeDataSetArray(action.payload);
                    break;
                case STORE_DATASET_FIELD:
                    if (action.payload.dataSetName && action.payload.fieldName) ownObject.storeDataSetField(action.payload);
                    break;
                case AUTH_LOGIN:
                    ownObject.storeDataSet({
                        dataSetName: "login",
                        data: { isAuthenticated: true }
                    });
                    actionDSetName = "login";
                    break;
                case AUTH_LOGOUT:
                    ownObject.storeDataSet({
                        dataSetName: "login",
                        data: { isAuthenticated: false }
                    });
                    actionDSetName = "login";
                    break;
                case SHOW_APP_BUSY:
                    ownObject.storeDataSet({
                        dataSetName: "isAppBusy",
                        data: true
                    });
                    actionDSetName = "isAppBusy";
                    break;
                case HIDE_APP_BUSY:
                    ownObject.storeDataSet({
                        dataSetName: "isAppBusy",
                        data: false
                    });
                    actionDSetName = "isAppBusy";
                    break;
                case STORE_USER: //Not used 
                    ownObject.storeDataSet({
                        dataSetName: "_userinfo",
                        data: action.payload.data
                    });
                    actionDSetName = "_userinfo";
                    break;

                case SUBMIT_BUTTON_TRIGGERED:
                    this.submitTriggered = true;
                    actionDSetName = "submitTriggered";

                    const stName = action.payload.stateName;
                    const stData = action.payload.data

                    if (stName && stName === "formReady") {
                        this.formReadyForSubmission += Number(stData) && Number(stData)
                    }

                    // Notify all subscribers
                    // this.notifySubscribers(actionDSetName);

                    // Set a timeout to revert submitTriggered back to false after 3 seconds
                    setTimeout(() => {
                        this.submitTriggered = false;
                        // this.notifySubscribers(actionDSetName);
                    }, 3000); // 3 seconds
                    break;
                default: break;
            }
            // if (debugstate) {
            //     if (window.debugstateds) {
            //         if (window.debugstateds === action?.payload?.dataSetName ||
            //             window.debugstateds === "*") {
            //             console.log("%c ▼▼▼", "color:magenta;");
            //             console.log(`%c ${action?.type} ${action?.payload?.dataSetName ? "on " + action.payload.dataSetName : ""}`, "background-color:#9c27b0;color:white;");
            //             console.log(action, "►►►", ownObject);
            //         }

            //     }
            //     if (window.debugstatefn) {
            //         console.log("%c ▼▼▼", "color:magenta;");
            //         console.log(action, "►►►", this.callBackFunctions);
            //     }
            // }
            this.callBackFunctions.forEach(fnObj => fnObj &&
                fnObj.fn({ dset: actionDSetName, index: action.payload?.index, field: action.payload?.fieldName }));
        }

    }
}

export const ownStore = new OwnState(); 
